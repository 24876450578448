import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import GtrModuleLayout from '@/modules/common/views/layouts/level-two/module/module.layout.vue'
import { Module } from '@/modules/common/enums/modules.enum'
import { Component } from 'vue-property-decorator'
import router from '@/bootstrap/router/router'
import Container from 'typedi'
import EventService from '@/modules/common/services/event/event.service'
import Notification from '@/modules/common/services/notification.service'

Component.registerHooks([
  'beforeRouteEnter'
])
@Component({
  name: 'GtrEvalsModuleIndexView'
})
export default class GtrEvalsModuleIndexView extends GtrSuper {
  created () {
    this.$emit('update:layout', GtrModuleLayout)
    this.$bus.$emit('update:menu', Module.SURVEYS)
  }

  async beforeRouteEnter (from, to, next) {
    const response = await Container.get(EventService).getEventModules(from.params.event_uuid)
    if (response.data) {
      const registrationModuleActive = response.data.SURVEYS.enabled
      if (registrationModuleActive) {
        next()
      } else {
        const message = 'Evals & Surveys Module not activated. Please, activate it and try again...'
        router.push({ name: 'level-two.event.modules', params: { uuid: from.params.uuid, event_uuid: from.params.event_uuid } }, () => {
          Container.get(Notification).error(message)
        })
      }
    }
    next(false)
  }
}
